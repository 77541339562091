export default function DividerColor(){
    return (
        <>
            <div className="divider-color w-full flex justify-center mt-4">
                <div className="bg-gray-200 w-1/3"></div>
                <div className="bg-red-500 w-12"></div>
                <div className="bg-gray-200 w-1/3"></div>
            </div>
        </>
    )
}