import { RoleType } from "@/types/next-auth"
import { Session } from "next-auth"

class RoleGuard {
    roles: RoleType[] = []

    getRoles(session: Session): RoleType[] {
        return session?.user?.roles ?? []
    }

    canRole(role: string, roles?: RoleType[]) {
        if (Array.isArray(roles)) {
            let tem = false
            roles?.forEach((item: any) => {
                if (item.role == role) {
                    tem = true
                }
            })

            return tem
        }
        return false
    }

    canPermission(nome: string, roles?: RoleType[]) {
        if (Array.isArray(roles)) {
            var tem = false;

            roles?.forEach((r: any) => {
                if ('permissions' in r) {
                    r.permissions.forEach((p: any) => {
                        if (p.nome == nome) {
                            tem = true
                        }
                    })
                }
            })

            return tem
        }

        return false
    }
}

export default new RoleGuard()