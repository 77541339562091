import { Inter } from '@next/font/google'
import { getSession, useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { GetServerSideProps } from 'next'
import Link from 'next/link'
import { Carousel, Button } from 'flowbite-react'
import DividerColor from '@/components/DividerColor'
import { LayoutHome } from '@/components/layouts/LayoutHome'
import { EventoModel } from '@/models/EventoModel'
import ApiHelper from '@/shared/ApiHelper'
import utils from '@/shared/utils'
import { FaCalendarDay, FaCalendarWeek, FaMapMarkerAlt } from 'react-icons/fa'
import CarouselCustom from '@/components/CarouselCustom'
import EventoController from "@/controllers/EventoController";
import { toast } from "react-toastify";
import { useRouter } from "next/router";

import { Swiper, SwiperSlide } from "swiper/react";
import styles from '../styles/styleSwipper.module.scss'

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
import { useScreen } from 'usehooks-ts'
import CartFooterComponent from '@/components/compra/CartFooter'

const inter = Inter({ subsets: ['latin'] })

type EventosProps = { estado: string, eventos: EventoModel[] }

type dadoProps = {
  eventos: EventosProps[],
  banners: [{
    id: number,
    nome_amigavel: string,
    banner: string,
    banner_mobile: string
  }]
}

type Props = {
  dados: dadoProps
  qtdCart: string, 
  error: string
}

interface Window {
  fbq: (...args: any[]) => void;
}

declare global {
  interface Window {
      fbq: (...args: any[]) => void;
  }
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  let dados: dadoProps[] = []

  try {

    const res = await ApiHelper.post({
      url: ApiHelper.eventos + '/site-home',
      method: 'POST',
      timezone: context.req.cookies['timezone'],
      data: {
        fuso: context.req.cookies['fuso']
      }
    })

    if (res.response) {
      dados = res.dados
    } else {
      const idToast = toast.loading("Salvando...");
      toast.update(idToast, {
        render: res?.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return {
        props: {
          dados: [],
          qtdCart: '0'
        }
      }
    }

    const session = await getSession(context);
    utils.deleleQtdCart()
    let qtdCart = '0'

    const resCarts = await EventoController.getCart({
      user_id: session?.user.sub,
      cookie_id: context.req.cookies.cart,
    });

    let soma = 0
    let total = 0
    let conveniencia = 0
    let taxa = 0
    let totalDesconto = 0
    let solicitarTitular = false
  
    if (resCarts.response) {
      resCarts.dados.forEach((item: any) => {
        taxa = item.evento.conveniencia ?? 0
        soma = soma + ((item.valor ?? 0) * item.quantidade)
        totalDesconto = totalDesconto + (item.desconto ?? 0)

        if (item.evento.titular_antecipado) {
          solicitarTitular = true
        }
      })
      utils.setQtdCartCookie(resCarts.dados, true);
  
      qtdCart = String(resCarts.dados.length)

      if (resCarts.dados.length === 0) {
        utils.setCookieCart(utils.randomNumber(), true)
        utils.setQtdCartCookie([])
      }
    }

    return {
      props: {
        dados,
        qtdCart
      }
    }
  } catch (error: any) {
      return {
        props: {error: error?.message || '', dados: [], qtdCart: '0'}
      }
  }
}

const Home = (props: Props) => {
  
  const { data: session } = useSession()
  const [teste, setTeste] = useState('')
  const [qtdCart, setQtdCart] = useState<any>(props.qtdCart)
  const { eventos, banners } = props.dados  
  const screen = useScreen()
  const [widthScreen, setWidthScreen] = useState(1900)
  const router = useRouter();

  useEffect(() => {
    if (screen) {
      setWidthScreen(screen.width)
    }
    // Limpar o Facebook Pixel
    if (typeof window.fbq === 'function') { 
      router.reload();
    }
  })

  if (props.error) {
    return (
      <LayoutHome qtdCart={0} marginBotton={60}>
        <div className='text-center grid text-gray-900 p-4 container m-auto justify-center items-center '>
          <h1>Erro ao carregar</h1>
          <div>{ props.error }</div>
        </div>
    </LayoutHome>
    )
  }

  return (
    <>
      <LayoutHome qtdCart={qtdCart} marginBotton={60}>    
        <div className=''>
          <div>
            <Swiper
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className={styles.mySwiper}
            >
              {banners.map((item, index) => (
                <>
                  <SwiperSlide className={`${styles.swiperitem} `}>
                    <Link href={`/evento/${item.nome_amigavel ? item.nome_amigavel : item.id}`}>
                      {widthScreen > 640 && (
                        <img
                          className="object-fill w-full sm:block hidden"
                          src={item.banner}
                          alt="image slide 2"
                        />
                      )}
                      {widthScreen < 640 && (
                        <img
                          className="object-fill w-full sm:hidden block"
                          src={item.banner_mobile}
                          alt="image slide 2"
                        />
                      )}
                    </Link>
                    
                  </SwiperSlide>
                </>
              ))}
            </Swiper>


            {/* <Carousel className='rounded-none' slide={true}>
              {banners.map((item, index) => (
                <>
                  <img
                    key={`carousel${index}`}
                    src={item.banner}
                    alt={`banner${index}`}
                    className='rounded-none w-full item-carousel sm:block hidden'
                  />
                  <img
                    key={`carousel-mobile${index}`}
                    src={item.banner_mobile}
                    alt={`banner${index}`}
                    className='rounded-none w-full item-carousel sm:hidden block'
                  />
                </>
              ))}
            </Carousel> */}
          </div>

          <div className='w-full flex justify-center items-center pt-14 pb-4'>
            <div className=' sm:w-1/3 w-full text-center h-11 sm:text-3xl text-2xl'>
              <strong className='text-myred uppercase text-md'>Eventos</strong>
              <DividerColor />
            </div>
          </div>

          {eventos.map((evento, indexEvento) => (
            <>

              {/* <div key={`evento-estado${indexEvento}`} className='w-full flex justify-center items-center pt-14 pb-4'>
                <div className=' sm:w-1/3 w-full text-center h-11 sm:text-3xl text-2xl'>
                  <strong className='text-myred uppercase text-md'>{evento.estado}</strong>
                  <DividerColor />
                </div>
              </div> */}
              <div key={`evento${indexEvento}`} className='container mx-auto mt-10 mb-10'>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 px-10'>
                  {evento.eventos.map((item, indexLink) => (
                    <Link
                      key={`evento-link${indexLink}`}
                      href={'/evento/' + (item.nome_amigavel ? item.nome_amigavel : item.id)}>
                      <div className='pb-5  shadow-xl bottom-1 border-white rounded-lg '>
                        <div
                          className='w-full item-evento-home rounded-tl-lg rounded-tr-lg '
                        >
                          {item.capa && (
                            <img key={`capa${item.id}$-${indexLink}`} src={item.capa} alt="" className='rounded-tl-lg rounded-tr-lg' />
                          )}
                          {!item.capa && (
                            <>
                              <div className='flex relative items-center justify-center'>
                                <img key={`capa${item.id}-${indexLink}`} src="/images/marcadagua.jpg" alt="" className='rounded-tl-lg rounded-tr-lg' />
                                <div className=' text-white absolute text-xl font-bold text-center'>{item.nome}</div>
                              </div>
                            </>
                          )}

                        </div>
                        <div className='flex justify-center' style={{ marginTop: "-16px" }}>
                          <div className='bg-myred text-white font-semibold text-center rounded-2xl px-10 text-md py-1 border border-white  shadow-red-500 shadow-sm-light' style={{ zIndex: 1000 }}>COMPRAR</div>
                        </div>
                        <div className='px-5 pt-3 text-center space-y-2 grid justify-center'>
                          <p className='text-1xl font-semibold text-sm uppercase'>{item.nome}</p>
                          <div className='text-xs text-gray-400 flex gap-3 m-auto items-center'>
                            <FaCalendarWeek className='text-myred' /> {item.diaPorExtenso} às {item.horaInicio}h
                          </div>

                          <div className='text-xs text-gray-400 flex gap-3 m-auto items-center'>
                            <FaMapMarkerAlt className='text-myred' /> {item.cidade}-{item.estado}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>

            </>
          ))}

        </div>
      </LayoutHome>

      <style jsx>
        {`
          .fita-topo{
            border-top: 1px solid #fff;
            margin-top: -2px;
          }
          .carousel-abaixo{
            min-height: 670px
          }
          .fita-base{
            margin-top: 660px;
          }
        `}
      </style>
    </>
  )
}
export default Home

Home.layout = LayoutHome